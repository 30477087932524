import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './InnerNavbarElements.styles';

//Import utils and helpers
import { convertFilters, isNavActive, navigation } from './helpers';
import { useTableContextProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

// Import components
import { Grid } from 'components/elements';

export const InnerNavbarElements = () => {
	const { filters, setFilters } = useTableContextProvider();
	const { t } = useTranslations();
	const classes = useStyles();

	const handleOnButtonClick = (value) => {
		const convertedFilters = convertFilters({ value, filters });
		setFilters(convertedFilters);
	};

	return (
		<Grid container spacing={3}>
			{navigation.map(({ label, value }) => (
				<Grid item key={label}>
					<Button
						onClick={() => handleOnButtonClick(value)}
						className={clsx({
							[classes.button]: true,
							[classes.active]: isNavActive({ value, filters }),
						})}
					>
						{t(label)}
					</Button>
				</Grid>
			))}
		</Grid>
	);
};
