import { ENDPOINTS } from 'helpers';
import services from 'services/services';

export const fetchOfficesCars = async ({
	models,
	model_name,
	year,
	managerProfileUuid,
	lng,
}) => {
	if (model_name.en || model_name.ar) {
		const modelUuid = getModelUuid({ models, model: model_name, lng });
		const vehicleModelUuidObj = {
			vehicle_model: {
				uuid: modelUuid,
			},
		};

		const body =
			typeof year === 'number'
				? { ...vehicleModelUuidObj, vehicle: { year: year } }
				: vehicleModelUuidObj;

		return await services.post(
			ENDPOINTS.CARS.OFFICES_CARS({ managerProfileUuid }),
			body
		);
	}
};

export const createCarYearsBody = ({
	fieldName,
	models,
	model_name,
	isGlobalEdit,
	vehicleList,
	manufacturer,
	lng,
}) => {
	if (fieldName && vehicleList.length !== 0) {
		const body = isGlobalEdit
			? {
					vehicle_model: {
						uuid: getModelUuid({ models, model: model_name, lng }),
					},
					//eslint-disable-next-line
			  }
			: {
					vehicle_maker: {
						uuid: getVehicleUuid({ vehicleList, manufacturer, lng }),
					},
					//eslint-disable-next-line
			  };

		return body;
	}
};

export const createCarModelsBody = ({
	fieldName,
	vehicleList,
	manufacturer,
	isGlobalEdit,
	year,
	lng,
}) => {
	if (fieldName && vehicleList.length !== 0) {
		const vehicleUuid = getVehicleUuid({ vehicleList, manufacturer, lng });

		const vehicleMakerUuid = {
			vehicle_maker: {
				uuid: vehicleUuid,
			},
		};

		const body = isGlobalEdit
			? vehicleMakerUuid
			: {
					...vehicleMakerUuid,
					vehicle: {
						year: year,
					},
					//eslint-disable-next-line
			  };

		return body;
	}
};

const getVehicleUuid = ({ vehicleList, manufacturer, lng }) =>
	vehicleList.find(
		({ name }) => name[lng] === manufacturer[lng] || name[lng] === manufacturer
	)?.uuid;

const getModelUuid = ({ models, model, lng }) =>
	models.find(({ model_name }) => model_name[lng] === model[lng]).model_uuid;
