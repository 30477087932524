import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	AcceptedBookingsNumberFilter,
	BookingStatusFilter,
	CustomerTypeFilter,
	ActiveStateFilter,
	DateRangeFilter,
} from 'components/elements';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<CustomerTypeFilter
				label="customers.filters.titles.customer_type"
				name="type"
			/>
			<ActiveStateFilter
				label="customers.filters.titles.customer_status"
				name="state"
			/>
			<AcceptedBookingsNumberFilter
				label="customers.filters.titles.accepted_bookings_number"
				name="accepted_booking_counter"
			/>
			<BookingStatusFilter
				label="customers.filters.titles.last_created_booking_status"
				name="last_booking_status"
				md={3}
			/>
			<DateRangeFilter
				nameFrom="created_at"
				nameUntil="created_at_to"
				label="customers.filters.titles.sign_up_date"
				combinedInputs
				md={3}
			/>
		</Grid>
	);
};
