import React from 'react';
import {
	array,
	bool,
	element,
	func,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

// Import components
import { TableProvider } from 'components/context';
import { Table, Toolbar } from 'components/elements';
import { TableNavbar } from './components';
import { Row } from './components/Row/Row';

export const TableRPC = ({
	columnsAccessor,
	columns,
	toolBarContent,
	tableBodyRow,
	fetchDataTableAction,
	defaultQueryParams = '',
	titleLoading = false,
	title,
	filtersBar,
	outerToolbarElements,
	additionalNavigationElements,
	tableNavigation,
	searchBarPlaceholder = 'common.search',
	showSearchInput = true,
	navbarMinHeight = 100,
	titleStyles = '',
	containerStyles = '',
	cellMaxWidth = '',
}) => {
	const classes = useBaseLayoutStyles();

	return (
		<TableProvider
			initialResources={{ cancelReasons: [], states: [] }}
			initialTableOptions={{ pageSize: 10 }}
			columns={columns}
			fetchDataTableAction={(options) =>
				fetchDataTableAction({ ...options, defaultQueryParams })
			}
		>
			<div className={clsx([classes.root, containerStyles])}>
				<TableNavbar
					outerToolbarElements={outerToolbarElements}
					navbarMinHeight={navbarMinHeight}
					titleLoading={titleLoading}
					titleStyles={titleStyles}
					title={title}
				/>
				{toolBarContent && <Toolbar content={toolBarContent} />}
				<div className={classes.content}>
					<Table
						additionalNavigationElements={additionalNavigationElements}
						tableNavigation={tableNavigation}
						filtersBar={filtersBar}
						isDefaultRow={false}
						searchBarPlaceholder={searchBarPlaceholder}
						showSearchInput={showSearchInput}
						cellMaxWidth={cellMaxWidth}
					>
						{({ row }) => {
							return (
								<Row
									columnsAccessor={columnsAccessor}
									tableBodyRow={tableBodyRow}
									key={row.id}
									row={row}
								/>
							);
						}}
					</Table>
				</div>
			</div>
		</TableProvider>
	);
};

TableRPC.propTypes = {
	columnsAccessor: object.isRequired,
	columns: array.isRequired,
	toolBarContent: array,
	tableBodyRow: oneOfType([element, func]).isRequired,
	export: element,
	fetchDataTableAction: func.isRequired,
	defaultQueryParams: string,
	title: string,
	titleLoading: bool,
	filtersBar: element,
	outerToolbarElements: element,
	additionalNavigationElements: element,
	tableNavigation: element,
	searchBarPlaceholder: string,
	showSearchInput: bool,
	navbarMinHeight: number,
	titleStyles: string,
	containerStyles: string,
	cellMaxWidth: string,
};
