/* eslint-disable react/prop-types */
import React from 'react';
import { t } from 'i18next';

// Import components
import { AmountTableCell, ReceiptDetailsTableCell } from './components';
import { DateTimeTableCell, ReceiptIdTableCell } from 'components/elements';

export const columns = [
	{
		header: 'common.fields.receipt_id',
		accessor: 'number',
		component: ({ number }) => <ReceiptIdTableCell text={number} />,
	},
	{
		header: 'common.fields.type',
		accessor: 'type',
		component: ({ type }) => t(`invoices.types.${type}`),
	},
	{
		header: 'common.fields.amount',
		accessor: 'amount',
		component: (row) => <AmountTableCell row={row} />,
	},
	{
		header: 'common.fields.issue_date',
		accessor: 'issue_date',
		component: ({ created_at: createdAt }) => (
			<DateTimeTableCell date={createdAt} />
		),
	},
	{
		header: 'common.fields.action',
		accessor: 'action',
		component: (row) => <ReceiptDetailsTableCell row={row} />,
	},
];
