import React from 'react';
import { number, string, arrayOf, shape, oneOfType, bool } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import { find } from 'lodash';

// Import components
import {
	SelectFieldAdapter,
	SelectFieldWithSearchAdapter,
} from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

export const BaseSelectFilter = ({
	name,
	label,
	options,
	md = 3,
	xs = 6,
	withSearch = false,
	smallFieldStyle = false,
	allLabel = 'common.all',
}) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const renderValue = (value) =>
		value === 'all' ? t(allLabel) : t(find(options, { value }).label);

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={
					withSearch ? SelectFieldWithSearchAdapter : SelectFieldAdapter
				}
				label={label ? t(label) : ''}
				name={name}
				variant="outlined"
				disabled={submitting}
				smallfieldstyle={smallFieldStyle}
				SelectProps={{ renderValue }}
			>
				{options.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{t(label)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

BaseSelectFilter.propTypes = {
	name: string.isRequired,
	label: string,
	withSearch: bool,
	md: oneOfType([number, string]),
	xs: oneOfType([number, string]),
	smallFieldStyle: bool,
	allLabel: string,
	options: arrayOf(
		shape({
			value: oneOfType([string, number, bool]).isRequired,
			label: string.isRequired,
		})
	).isRequired,
};
