import currency from './currency.json';
import common from './common.json';
import table from './table.json';
import nav from './nav.json';
import dashboard from './dashboard.json';
import bookings from './bookings.json';
import scheduled_bookings from './scheduled_bookings.json';
import last_booking from './last_booking.json';
import offices from './offices.json';
import errors from './errors.json';
import form from './form.json';
import sign_up from './sign_up.json';
import sign_in from './sign_in.json';
import forget_password from './forgot_password.json';
import profile from './profile.json';
import company from './company.json';
import booking_print from './booking_print.json';
import company_managers from './company_managers.json';
import users from './users.json';
import cars from './cars.json';
import customers from './customers.json';
import organizations from './organizations.json';
import discounts from './discounts.json';
import promo_codes from './promo_codes.json';
import cancellation_reasons from './cancellation_reasons.json';
import sliders from './sliders.json';
import cms from './cms.json';
import public_holidays from './public_holidays.json';
import special_offer from './special_offer.json';
import drop_cities from './drop_cities.json';
import table_export from './table_export.json';
import mobile_app from './mobile_app.json';
import rating_reasons from './rating_reasons.json';
import urgent_reasons from './urgent_reasons.json';
import waiting_customer_info from './waiting_customer_info.json';
import validation from './validation.json';
import kiosk from './kiosk.json';
import campaigns from './campaigns.json';
import cars_availability from './cars_availability.json';
import employee_rewarding from './employee_rewarding.json';
import customer_balance from './customer_balance.json';
import b2b from './b2b.json';
import rewards_settlement from './rewards_settlement.json';
import contract from './contract.json';
import faq from './faq.json';
import set_password from './set_password.json';
import invoices from './invoices';
import return_page from './return_page';

export default {
	currency,
	common,
	table,
	nav,
	dashboard,
	bookings,
	scheduled_bookings,
	last_booking,
	offices,
	errors,
	form,
	sign_up,
	sign_in,
	forget_password,
	profile,
	company,
	booking_print,
	company_managers,
	users,
	cars,
	customers,
	organizations,
	discounts,
	promo_codes,
	cancellation_reasons,
	sliders,
	special_offer,
	cms,
	public_holidays,
	drop_cities,
	table_export,
	mobile_app,
	rating_reasons,
	urgent_reasons,
	waiting_customer_info,
	validation,
	kiosk,
	campaigns,
	cars_availability,
	employee_rewarding,
	customer_balance,
	b2b,
	rewards_settlement,
	contract,
	faq,
	set_password,
	invoices,
	return_page,
};
