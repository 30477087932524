import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	BaseYesNoAllFilter,
	PaidOnlineFilter,
	DeliveryFilter,
} from 'components/elements';

export const FilterFields = () => (
	<Grid container spacing={3}>
		<DeliveryFilter />
		<PaidOnlineFilter />
		<BaseYesNoAllFilter
			label="bookings.filters.kiosk"
			name="is_kiosk"
			xs={6}
			md={6}
		/>
	</Grid>
);
