import React from 'react';

// Import store
import { fetchLastBooking } from 'store/actions';

// Import components
import {
	AdditionalInfo,
	InnerNavbarElements,
	TableBodyRow,
} from './components';
import { TableRPC } from 'components/utilities';
import { Filters } from './components';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from './columns';

export const LastBooking = () => (
	<TableRPC
		columnsAccessor={COLUMNS_ACCESSORS}
		columns={columns}
		tableBodyRow={TableBodyRow}
		fetchDataTableAction={fetchLastBooking}
		title="nav.last_booking"
		filtersBar={<Filters />}
		tableNavigation={<InnerNavbarElements />}
		additionalNavigationElements={
			<AdditionalInfo
				showAssignedDriverAdditional
				showDeliveryStatusAdditional
			/>
		}
	/>
);
