import React from 'react';
import { object } from 'prop-types';

// Import components
import { IconButton } from 'components/elements';
import { ReceiptDetailsDialog } from './components';

// Import assets
import { DownloadIcon } from 'assets/icons';

// Import utils
import { useReceiptDetailsTableCell } from './useReceiptDetailsTableCell';

export const ReceiptDetailsTableCell = ({ row }) => {
	const { isReceiptDialogOpen, toggleReceiptDialog } =
		useReceiptDetailsTableCell();
	return (
		<>
			<IconButton onClick={toggleReceiptDialog}>
				<DownloadIcon />
			</IconButton>
			<ReceiptDetailsDialog
				isOpen={isReceiptDialogOpen}
				toggle={toggleReceiptDialog}
				row={row}
			/>
		</>
	);
};

ReceiptDetailsTableCell.propTypes = {
	row: object.isRequired,
};
