import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const INVOICES_LIST_ROWS_PER_PAGE = [50, 100, 200, 300];

export const INITIAL_VALUES = {
	company_uuid: ['all'],
	office_uuid: ['all'],
	created: {
		from: null,
		until: null,
	},
};

export const getFormattedFilterValues = (values) => {
	const { created, company_uuid, office_uuid } = values;

	let formattedValues = {
		...(created?.from && {
			'created.from': created?.from
				? format(created.from, DEFAULT_DATE_FORMAT)
				: null,
			'created.until': created?.until
				? format(created.until, DEFAULT_DATE_FORMAT)
				: null,
		}),
		company_uuid: company_uuid?.[0] === 'all' ? null : company_uuid,
		office_uuid: office_uuid?.[0] === 'all' ? null : office_uuid,
	};

	return formattedValues;
};
