import { every, isDate, isEmpty, isBoolean } from 'lodash';

export const checkIfAllValuesEmptyOrSpecificString = ({
	object,
	string = '',
}) =>
	every(object, (value) => {
		const stringValue = value?.toString();
		return (
			((isEmpty(stringValue) && !isBoolean(value)) ||
				stringValue?.includes(string)) &&
			!isDate(stringValue)
		);
	});
