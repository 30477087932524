import React from 'react';

// Import roles
import {
	OFFICE_MANAGERS,
	ADMINS,
	COMPANY_MANAGERS_WITHOUT_ADMINS,
	OFFICE_MANAGER,
	COMPANY_MANAGER,
	replaceEditCompanyUrl,
	CORPORATE_COORDINATOR,
	COMPANY_MANAGERS,
} from 'helpers';

// Import helpers
import { defaultSeoCollapsedItems } from './helpers';
import { URLS } from 'components/routes';

// Import components
import {
	WaitingCustomerInfoCountChip,
	BookingAccountingCountChip,
	UrgentBookingCountChip,
	ValidationCountChip,
	PendingActionsCountChip,
} from 'components/elements';

// Import icons
import {
	OrganizationsIcon,
	DashboardIcon,
	BookingsIcon,
	LastBookingsIcon,
	OfficesIcon,
	CustomersIcon,
	CompanyManagersIcon,
	OperationsIcon,
	WalletIcon,
	KioskIcon,
	PublicHolidaysIcon,
	CalendarIconNav,
	EmployeePerformanceIcon,
	UsersIcon,
	SettingIcon,
	CarSquareIcon,
	SubscriptionCarIcon,
	InvoiceIcon,
} from 'assets/icons';

export const nav_links = (seoTypes, companyUuid) => [
	{
		title: 'nav.dashboard',
		href: URLS.dashboardUrl,
		icon: <DashboardIcon />,
		role: OFFICE_MANAGERS,
	},
	{
		title: 'nav.booking',
		icon: <BookingsIcon />,
		role: OFFICE_MANAGERS,
		collapsed: [
			{
				title: 'nav.bookings_list',
				href: URLS.bookingsUrl,
				role: OFFICE_MANAGERS,
			},
			{
				title: 'nav.customer_care.late_delivery_booking',
				href: URLS.lateDeliveryBookingsUrl,
				role: COMPANY_MANAGERS_WITHOUT_ADMINS,
			},
			{
				title: 'nav.customer_care.return_bookings',
				href: URLS.returnBookingsUrl,
				role: ADMINS,
			},
		],
	},
	{
		title: 'nav.last_booking',
		href: URLS.lastBookingUrl,
		chip: PendingActionsCountChip,
		icon: <LastBookingsIcon />,
		role: OFFICE_MANAGERS,
	},
	{
		title: 'nav.customer_care.scheduled_booking',
		href: URLS.scheduledBookingsUrl,
		icon: <CalendarIconNav />,
		role: COMPANY_MANAGERS_WITHOUT_ADMINS,
	},
	{
		title: 'nav.customer_care.return_bookings',
		href: URLS.returnBookingsUrl,
		icon: <CalendarIconNav />,
		role: COMPANY_MANAGERS_WITHOUT_ADMINS,
	},
	{
		title: 'nav.accounting_validation.invoices_list',
		href: URLS.invoicesListUrl,
		icon: <InvoiceIcon />,
		role: COMPANY_MANAGERS_WITHOUT_ADMINS,
	},
	{
		title: 'nav.corporate.title',
		icon: <CompanyManagersIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.corporate.corporate_list',
				href: URLS.corporateListUrl,
				role: ADMINS,
			},
			{
				title: 'nav.corporate.select_rental_companies',
				href: URLS.rentalCompaniesUrl,
				role: ADMINS,
			},
			{
				title: 'nav.corporate.corporate_maintenance',
				href: URLS.corporateMaintenanceUrl,
				role: ADMINS,
			},
		],
	},
	{
		title: 'nav.customer_care.title',
		icon: <CompanyManagersIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.customer_care.waiting_info',
				href: URLS.waitingCustomerInfoUrl,
				chip: WaitingCustomerInfoCountChip,
				role: ADMINS,
			},
			{
				title: 'nav.customer_care.scheduled_booking',
				href: URLS.scheduledBookingsUrl,
				role: ADMINS,
			},
			{
				title: 'nav.customer_care.late_delivery_booking',
				href: URLS.lateDeliveryBookingsUrl,
				role: ADMINS,
			},
			{
				title: 'nav.customer_care.faqs',
				href: URLS.faqsUrl,
				role: ADMINS,
			},
		],
	},
	{
		title: 'nav.operations.title',
		icon: <OperationsIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.operations.urgent_bookings',
				href: URLS.urgentBookingsUrl,
				chip: UrgentBookingCountChip,
				role: ADMINS,
			},
			{
				title: 'nav.operations.compensation_validation',
				href: URLS.compensationValidationUrl,
				chip: ValidationCountChip,
				role: ADMINS,
			},
			{
				title: 'nav.operations.cancellation_validation',
				href: URLS.cancellationValidationUrl,
				role: ADMINS,
			},
			{
				title: 'nav.operations.cancellation_by_customer',
				href: URLS.cancellationByCustomerUrl,
				role: ADMINS,
			},
			{
				title: 'nav.operations.reports',
				href: URLS.reportsUrl,
				role: ADMINS,
			},
			{
				title: 'nav.operations.cars_availability',
				href: URLS.carsAvailabilityUrl,
				role: ADMINS,
			},
		],
	},
	{
		title: 'nav.accounting_validation.title',
		icon: <OrganizationsIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.accounting_validation.compensations_and_refunds',
				href: URLS.accountingValidationUrl,
				chip: BookingAccountingCountChip,
				role: ADMINS,
			},
			{
				title: 'nav.accounting_validation.rewards_settlement',
				href: URLS.rewardsSettlementUrl,
				role: ADMINS,
			},
			{
				title: 'nav.accounting_validation.invoices_list',
				href: URLS.invoicesListUrl,
				role: ADMINS,
			},
		],
	},
	{
		title: 'nav.subscriptions.title',
		icon: <SubscriptionCarIcon />,
		role: COMPANY_MANAGERS,
		collapsed: [
			{
				title: 'nav.subscriptions.subscriptions_list',
				href: URLS.subscriptionListUrl,
				role: COMPANY_MANAGERS,
			},
			{
				title: 'nav.subscriptions.payment_collection',
				href: URLS.paymentCollectionUrl,
				role: COMPANY_MANAGERS,
			},
		],
	},
	{
		title: 'nav.campaigns.title',
		icon: <WalletIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.campaigns.campaign',
				href: URLS.campaignsUrl,
				role: ADMINS,
			},
		],
	},

	{
		title: 'nav.marketing.title',
		icon: <CustomersIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.marketing.special_offer',
				href: URLS.specialOfferUrl,
				role: ADMINS,
			},
			{
				title: 'nav.marketing.promo_codes',
				href: URLS.promoCodesUrl,
				role: ADMINS,
			},
			{
				title: 'nav.marketing.sliders',
				href: URLS.slidersUrl,
				role: ADMINS,
			},
			{
				title: 'nav.marketing.default_seo.title',
				role: ADMINS,
				collapsed: defaultSeoCollapsedItems(seoTypes),
			},
			{
				title: 'nav.marketing.cms.title',
				role: ADMINS,
				collapsed: [
					{
						title: 'nav.marketing.cms.partners',
						href: URLS.cmsPartnersUrl,
						role: ADMINS,
					},
					{
						title: 'nav.marketing.cms.partners_positions',
						href: URLS.cmsPartnersPositionsUrl,
						role: ADMINS,
					},
					{
						title: 'nav.marketing.cms.pages',
						href: URLS.cmsPagesUrl,
						role: ADMINS,
					},
					{
						title: 'nav.marketing.cms.cities',
						href: URLS.cmsCitiesUrl,
						role: ADMINS,
					},
				],
			},
			{
				title: 'nav.marketing.mobile_app.title',
				role: ADMINS,
				collapsed: [
					{
						title: 'nav.marketing.mobile_app.system_clients',
						href: URLS.mobileAppSystemClientsUrl,
						role: ADMINS,
					},
				],
			},
		],
	},
	{
		title: 'nav.employee_performance',
		href: URLS.employeeRewardingUsersUrl,
		icon: <EmployeePerformanceIcon />,
		role: COMPANY_MANAGER,
	},
	{
		title: 'nav.performance',
		href: URLS.employeeRewardingBookingsUrl,
		icon: <EmployeePerformanceIcon />,
		role: OFFICE_MANAGER,
	},
	{
		title: 'nav.offices.title',
		icon: <OfficesIcon />,
		role: COMPANY_MANAGERS_WITHOUT_ADMINS,
		collapsed: [
			{
				title: 'nav.offices.offices_list',
				href: URLS.officesUrl,
				role: COMPANY_MANAGERS_WITHOUT_ADMINS,
			},
			{
				title: 'nav.offices.public_holidays',
				href: URLS.publicHolidaysUrl,
				role: COMPANY_MANAGER,
			},
		],
	},
	{
		title: 'nav.companies.title',
		icon: <OfficesIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.companies.companies_list',
				href: URLS.companiesListUrl,
				role: ADMINS,
			},
			{
				title: 'nav.companies.offices',
				href: URLS.officesUrl,
				role: ADMINS,
			},
			{
				title: 'nav.companies.drop_cities',
				href: URLS.companyDropCitiesListUrl,
				role: ADMINS,
			},
			{
				title: 'nav.companies.company_managers',
				href: URLS.companyManagersUrl,
				role: ADMINS,
			},
			{
				title: 'nav.administrative.drivers',
				href: URLS.driversUrl,
				role: ADMINS,
			},
			{
				title: 'nav.companies.public_holidays',
				href: URLS.publicHolidaysUrl,
				role: ADMINS,
			},
			{
				title: 'nav.companies.cars.title',
				role: OFFICE_MANAGERS,
				collapsed: [
					{
						title: 'nav.companies.cars.manufacturer',
						href: URLS.carManufacturerUrl,
						role: ADMINS,
					},
					{
						title: 'nav.companies.cars.models',
						href: URLS.carModelsUrl,
						role: ADMINS,
					},
					{
						title: 'nav.companies.cars.car_list',
						href: URLS.carListUrl,
						role: OFFICE_MANAGERS,
					},
				],
			},
		],
	},
	{
		title: 'nav.cars.title',
		icon: <CarSquareIcon />,
		role: COMPANY_MANAGERS_WITHOUT_ADMINS,
		collapsed: [
			{
				title: 'nav.cars.cars_availability',
				href: URLS.carsAvailabilityUrl,
				role: COMPANY_MANAGERS_WITHOUT_ADMINS,
			},
			{
				title: 'nav.cars.cars_plates',
				href: URLS.carListUrl,
				role: COMPANY_MANAGERS_WITHOUT_ADMINS,
			},
		],
	},
	{
		title: 'nav.users.title',
		icon: <UsersIcon />,
		role: COMPANY_MANAGER,
		collapsed: [
			{
				title: 'nav.users.company_office_managers',
				href: URLS.companyManagersUrl,
				role: COMPANY_MANAGER,
			},
			{
				title: 'nav.users.drivers',
				href: URLS.driversUrl,
				role: COMPANY_MANAGER,
			},
		],
	},
	{
		title: 'nav.configuration',
		href: replaceEditCompanyUrl(companyUuid),
		icon: <SettingIcon />,
		role: COMPANY_MANAGER,
	},
	{
		title: 'nav.administrative.title',
		icon: <PublicHolidaysIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.administrative.users',
				href: URLS.usersUrl,
				role: ADMINS,
			},
			{
				title: 'nav.administrative.organizations',
				href: URLS.organizationsUrl,
				role: ADMINS,
			},
			{
				title: 'nav.administrative.customers.title',
				role: ADMINS,
				collapsed: [
					{
						title: 'nav.administrative.customers.customers_list',
						href: URLS.customersUrl,
						role: ADMINS,
					},
					{
						title: 'nav.administrative.customers.sign_up_process',
						href: URLS.customerRegistrationUrl,
						role: ADMINS,
					},
					{
						title: 'nav.administrative.customers.driver_license_list',
						href: URLS.driverLicenseList,
						role: ADMINS,
					},
				],
			},
			{
				title: 'nav.administrative.action_reasons.title',
				role: ADMINS,
				collapsed: [
					{
						title: 'nav.administrative.action_reasons.cancellation_reasons',
						href: URLS.cancellationReasonsUrl,
						role: ADMINS,
					},
					{
						title: 'nav.administrative.action_reasons.rating_reasons',
						href: URLS.ratingReasonsUrl,
						role: ADMINS,
					},
					{
						title: 'nav.administrative.action_reasons.urgent_reasons',
						href: URLS.urgentReasonsUrl,
						role: ADMINS,
					},
				],
			},
		],
	},
	{
		title: 'nav.kiosk.title',
		icon: <KioskIcon />,
		role: ADMINS,
		collapsed: [
			{
				title: 'nav.kiosk.airports',
				href: URLS.kioskAirportsUrl,
				role: ADMINS,
			},
			{
				title: 'nav.kiosk.management',
				href: URLS.kioskManagementUrl,
				role: ADMINS,
			},
		],
	},
	{
		title: 'nav.corporate.overview',
		href: URLS.corporateOverviewUrl,
		icon: <DashboardIcon />,
		role: CORPORATE_COORDINATOR,
	},
];
