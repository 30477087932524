import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { queryCache } from 'react-query';
import { string, bool } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';
import { CarDetailsFields } from './components';
import { useCarYears, useCarModels, useSupplierVehicleList } from 'queries';

// Import actions
import * as actions from 'store/actions';

// Import helpers
import { QUERY_KEYS, fieldNames, getGlobalUuid } from './helpers';

// Import translations
import { useTranslations } from 'components/utilities';

const CarDetails = ({
	className,
	isGlobalEdit = false,
	isGlobalAdd = false,
	...rest
}) => {
	const { values, initialValues } = useFormState();

	const { change } = useForm();

	const dispatch = useDispatch();

	const { clearCarInfoData } = actions;

	const { t, i18n } = useTranslations();

	const lng = i18n.language;

	const { vehicleList } = useSelector((state) => state.car.form);

	useEffect(() => {
		return () => {
			queryCache.cancelQueries(
				(query) => !Object.values(QUERY_KEYS).includes(query)
			);
			queryCache.removeQueries(
				(query) => !Object.values(QUERY_KEYS).includes(query)
			);
			clearCarInfoData()(dispatch);
		};
		//eslint-disable-next-line
	}, []);

	const {
		carInfo: {
			vehicle: { maker_name: manufacturer, model_name, year },
		},
	} = values;

	const {
		carInfo: {
			vehicle: {
				maker_name: initialManufacturer,
				model_name: initialModel,
				year: initialYear,
			},
		},
	} = initialValues;

	const { modelFieldName, yearFieldName } = fieldNames({
		lng,
	});

	const {
		data: years,
		isFetching: loadingYears,
		isError: yearsError,
	} = useCarYears({
		isGlobalEdit,
		vehicleList,
		manufacturer,
		model_name,
		initialManufacturer,
		initialModel,
		yearFieldName,
		modelFieldName,
		lng,
	});

	const {
		data: models,
		isFetching: fetchingModels,
		isError: modelsError,
	} = useCarModels({
		isGlobalEdit,
		vehicleList,
		manufacturer,
		year,
		initialYear,
		initialManufacturer,
		modelFieldName,
		lng,
	});

	const yearsArray = (!loadingYears && years) || [];
	const modelsArray = (!fetchingModels && models) || [];
	const carModel = model_name ? model_name[lng] : '';

	const queryFetchStates = {
		loadingModels: fetchingModels,
		loadingYears,
		yearsError,
		modelsError,
	};

	const getVehicleUuid = ({ vehicleList, manufacturer, lng }) =>
		vehicleList?.find(({ name }) => name[lng] === manufacturer[lng])?.uuid;

	const vehicleMakerUuid = getVehicleUuid({ vehicleList, manufacturer, lng });

	const { data } = useSupplierVehicleList({ year, vehicleMakerUuid });

	const uuid = getGlobalUuid({ supplierVehicleList: data, values });
	useEffect(() => {
		if (uuid) {
			change('carInfo.vehicle.global_uuid', uuid);
		}
		// eslint-disable-next-line
	}, [uuid, year]);

	const fieldsData = {
		vehicleList,
		yearsArray,
		year,
		modelsArray,
		carModel,
		manufacturer,
	};

	return (
		<>
			<Card {...rest} className={className}>
				<CardHeader title={t('cars.form.car_details.title')} />
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<CarDetailsFields
							className={className}
							isGlobalEdit={isGlobalEdit}
							isGlobalAdd={isGlobalAdd}
							queryFetchStates={queryFetchStates}
							fieldsData={fieldsData}
						/>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

CarDetails.propTypes = {
	section: string.isRequired,
	isGlobalEdit: bool,
	isGlobalAdd: bool,
	className: string,
};

export default CarDetails;
