// Import helpers
import {
	convertFormDates,
	getFilterIndex,
	removePreviousFilterValues,
	spreadFilterArray,
} from 'helpers';

export const initialValues = {
	is_delivery: false,
	is_payment_online: false,
	is_kiosk: 'all',
	company_uuid: ['all'],
	state: null,
	pick_date: null,
	pick_date_to: null,
};

export const formatValues = (values, { inputColumns }) => {
	const companyFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: 39,
	});

	const newValues = removePreviousFilterValues({
		values,
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: 39,
	});

	return {
		...newValues,
		...convertFormDates(values, ['pick_date']),
		...spreadFilterArray({
			arrayElements: values.company_uuid,
			filterIndex: companyFilterIndex,
			defaultFilterIndex: 39,
			inputColumns,
		}),
		is_payment_online: values.is_payment_online || null,
		is_kiosk: values.is_kiosk === 'all' ? null : values.is_kiosk,
		state: values.state === 'all' ? null : values.state,
		is_delivery: values.is_delivery || null,
		company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
	};
};
