import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		color: theme.palette.gray.medium,
		borderRadius: '12px',
		padding: '8px 16px',
		fontWeight: '600',
		fontSize: '14px',
		'& :hover': {
			backgroundColor: 'transparent',
		},
	},
	active: {
		backgroundColor: theme.palette.primary.medium,
		color: theme.palette.primary.main,
	},
}));
