import React from 'react';

// Import components
import {
	DateRangeInput,
	Grid,
	MultiCompaniesFilter,
	MultiOfficesFilter,
} from 'components/elements';

// Import utils and helpers
import RoleProtected from 'components/hoc/RoleProtected';
import { ADMIN, COMPANY_MANAGERS_WITHOUT_ADMINS } from 'helpers';

export const Filters = () => {
	return (
		<>
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="created.from"
					nameUntil="created.until"
					label="common.fields.created_at"
					combinedInputs
					isToggleButton
				/>
			</Grid>

			<RoleProtected roles={[ADMIN]}>
				<MultiCompaniesFilter
					name="company_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			</RoleProtected>

			<RoleProtected roles={[COMPANY_MANAGERS_WITHOUT_ADMINS]}>
				<MultiOfficesFilter
					name="office_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			</RoleProtected>
		</>
	);
};
