import _ from 'lodash';

const NAVIGATION_STATES = {
	UNASSIGNED: 'has_driver_assigned',
	EXTENDED: 'has_extension',
	REJECTED: 'rejected',
	PENDING: 'pending',
	ALL: null,
};

export const navigation = [
	{
		label: 'common.all',
		value: NAVIGATION_STATES.ALL,
	},
	{
		label: 'common.pending',
		value: NAVIGATION_STATES.PENDING,
	},
	{
		label: 'common.extended',
		value: NAVIGATION_STATES.EXTENDED,
	},
	{
		label: 'common.rejected',
		value: NAVIGATION_STATES.REJECTED,
	},
	{
		label: 'common.unassigned',
		value: NAVIGATION_STATES.UNASSIGNED,
	},
];

export const convertFilters = ({ filters, value }) => {
	switch (value) {
		case NAVIGATION_STATES.PENDING:
		case NAVIGATION_STATES.REJECTED: {
			const convertedFilters = _.omit(filters, [
				'has_driver_assigned',
				'extension',
			]);
			return { ...convertedFilters, state: value };
		}

		case NAVIGATION_STATES.UNASSIGNED: {
			const convertedFilters = _.omit(filters, ['state', 'extension']);
			return { ...convertedFilters, has_driver_assigned: false };
		}

		case NAVIGATION_STATES.EXTENDED: {
			const convertedFilters = _.omit(filters, [
				'has_driver_assigned',
				'state',
			]);
			return { ...convertedFilters, extension: 'accepted' };
		}

		default: {
			const convertedFilters = _.omit(filters, [
				'has_driver_assigned',
				'extension',
				'state',
			]);

			return convertedFilters;
		}
	}
};

export const isNavActive = ({ filters, value }) => {
	switch (value) {
		case NAVIGATION_STATES.PENDING:
		case NAVIGATION_STATES.REJECTED: {
			return filters?.state === value;
		}

		case NAVIGATION_STATES.UNASSIGNED: {
			return (
				filters?.has_driver_assigned != null && !filters?.has_driver_assigned
			);
		}

		case NAVIGATION_STATES.EXTENDED: {
			return filters?.extension;
		}

		default: {
			return (
				typeof filters?.has_driver_assigned !== 'boolean' &&
				typeof filters?.extension !== 'string' &&
				typeof filters?.state !== 'string'
			);
		}
	}
};
