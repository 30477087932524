export const ENDPOINTS = {
	ADMIN: {
		USERS_LIST: '/v2/user/list',
	},
	AIRPORTS: {
		NEAREST_AIRPORTS: '/v2/airport/terminal/nearest/collection',
	},
	B2B: {
		EXTEND_BOOKING_PRICE_CALCULATION: ({ bookingUuid, dropDate }) =>
			`/v2/coordinator/booking/${bookingUuid}/extend/calculate?drop_date=${dropDate}`,
		RENTAL_COMPANIES: '/dashboard/b2b/configuration',
		COORDINATOR: ({ corporateUuid }) =>
			`/dashboard/b2b/corporate/${corporateUuid}/coordinator`,
		CORPORATE: ({ corporateUuid }) =>
			`/dashboard/b2b/corporate/${corporateUuid}`,
		CORPORATION_CUSTOMER: ({ corporateUuid, customerUuid }) =>
			`/dashboard/b2b/corporate/${corporateUuid}/customer/${customerUuid}`,
		CORPORATION_CUSTOMERS_LIST: ({ corporateUuid }) =>
			`/dashboard/b2b/corporate/${corporateUuid}/customer`,
	},
	CITIES: {
		CITIES_COLLECTION: '/v2/city/collection',
		COMPANY_CITIES_COLLECTION: ({ companyUuid }) =>
			`/v2/supplier/business/company/${companyUuid}/city/collection`,
	},
	BOOKINGS: {
		BOOKINGS_COUNTER_REFERENCE: ({ reference }) =>
			`v2/booking/${reference}/counts`,
		BOOKING_HISTORY: ({ bookingId }) => `/v2/booking/${bookingId}/history`,
		BOOKING_INVOICE: ({ bookingUuid }) => `/v2/booking/${bookingUuid}/invoice`,
		BOOKING_INVOICE_UUID: ({ bookingUuid }) =>
			`/booking/${bookingUuid}/invoice`,
		BOOKING_PREVIEW: ({ uuid }) => `/v2/booking/${uuid}/preview`,
		BOOKING_STATES: '/v2/booking/states',
		EDIT_BOOKING_HISTORY: ({ uuid }) => `/v2/booking/${uuid}/edit/history`,
		BOOKING_OFFICES_COLLECTION: ({ bookingUuid }) =>
			`/v2/booking/${bookingUuid}/items/search/office/collection`,
		BOOKING_OFFICE_RATING_HISTORY: ({ bookingUuid }) =>
			`/v2/booking/${bookingUuid}/rating/history`,
		BOOKING_TRANSACTION_HISTORY: ({ bookingId }) =>
			`/v2/transaction/${bookingId}/booking`,
		URGENT_BOOKINGS_STATS: '/v2/booking/operation/urgent/stats',
		RATING_SUMMARY: ({ bookingUuid }) =>
			`v2/booking/${bookingUuid}/rating/summary`,
		OPEN_CONTRCAT: (uuid) => `/v2/booking/${uuid}/open-contract`,
		INVOICE_DETAILS: ({ uuid }) => `/invoice/${uuid}`,
	},
	CANCELLATION: {
		CANCEL_REASONS: '/v2/booking/cancel-reasons',
		CUSTOMER_CANCELLATION_REASONS: '/v2/booking/customer/cancel-reasons',
	},
	EARLY_RETURN: {
		CALCULATIONS: ({ uuid, returnDate }) =>
			`/v2/booking/${uuid}/early-return/calculate?return_date=${returnDate}`,
	},
	URGENT: {
		REASONS: '/v2/booking/urgent-assistance-reasons',
	},
	CAMPAIGNS: {
		BOOKINGS: ({ campaignUuid }) =>
			`/v2/customer/wallet-campaign/${campaignUuid}/bookings`,
		CONDITIONS: ({ campaignType }) =>
			`/v2/customer/wallet-campaign/${campaignType}/condition`,
		CUSTOMERS: ({ campaignUuid }) =>
			`/v2/customer/wallet-campaign/${campaignUuid}/customers`,
		GRATIFICATIONS: ({ campaignType }) =>
			`/v2/customer/wallet-campaign/${campaignType}/gratification`,
	},
	CARS: {
		MANUFACTURES: '/v2/supplier/vehicle/maker/list',
		MODEL_YEARS: '/v2/supplier/vehicle/model/year/list',
		PLATE_FORM_OPTIONS: '/v2/vehicle/plate/form-options',
		MODELS: '/v2/supplier/vehicle/model/list',
		TYPES: '/v2/supplier/vehicle/type/list',
		ALL_MODELS: '/v2/vehicle/model/list',
		ALTERNATIVE_CARS: ({ uuid }) => `/v2/booking/${uuid}/alternative-cars`,
		AUTO_MAKER_COLLECTION: '/v2/auto/vehicle/maker/collection',
		AUTO_MODEL_COLLECTION: '/v2/auto/vehicle/model/collection',
		SUPPLIER_CARS: '/v2/supplier/vehicle/list',
		LOG_HISTORY: ({ uuid }) => `/v2/messaging/event-store/${uuid}/log`,
		SUPPLIER_MODEL_YEARS: '/v2/supplier/vehicle/year/list',
		COMPANY_CAR_FILTER: '/v2/supplier/business/company/collection/car-filter',
		EXTERNAL_PARTNERS_CARS: ({ officeUuid }) =>
			`/v2/supplier/business/office/${officeUuid}/external-partners/cars-mapping/list`,
		OFFICES_CARS: ({ managerProfileUuid }) =>
			`/v2/supplier/business/car/company/${managerProfileUuid}/list`,
		SUPPLIER_VEHICLE_LIST: '/v2/supplier/vehicle/list',
		SUPPLIER_VEHICLE_YEAR_LIST: '/v2/supplier/vehicle/year/list',
		SEARCH: ({ bookingUuid }) => `/v2/booking/${bookingUuid}/items/search`,
	},
	SUBSCRIPTION: {
		PRICES: ({ convertedParams }) =>
			`/v2/subscription/prices?${convertedParams}`,
	},
	COMPANIES: {
		COLLECTION: ({ isActive }) =>
			`/v2/supplier/business/company/collection${isActive ? '-active' : ''}`,
		DETAILS: ({ uuid }) => `/v2/supplier/business/company/${uuid}/show`,
	},
	CUSTOMER: {
		SUMMARY_DATA: ({ customerUuid }) => `/v2/customer/${customerUuid}/summary`,
	},
	PARTNERS: {
		COLLECTION: '/external-partners/collection',
	},
	RETURN_PAGE: {
		CLOSE_CONTRACT: ({ bookingUuid }) =>
			`/contract/booking/${bookingUuid}/close`,
		EXTEND_CONTRACT: ({ bookingUuid }) =>
			`/contract/booking/${bookingUuid}/extend`,
	},
	INVOICES: {
		INVOICES_FOR_BOOKING: ({ invoiceUuid }) =>
			`/invoice/${invoiceUuid}/sequence`,
		INVOICES_LIST: '/invoice',
	},
};
